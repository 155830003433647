import React from "react";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-9.svg";

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

const FiveColumnWithBackground = () => {
  return (
    <Container>
      <Content>
        <ThreeColRow>
          <LogoContainer>
            <div className="footer-logo-off">
              <svg
                width="222"
                height="108"
                viewBox="0 0 222 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.36 60.8491C6.36 76.9638 14.4467 84.6518 28.3493 84.6518C42.2507 84.6518 50.336 76.9638 50.336 60.8491C50.336 44.6518 42.2507 36.9651 28.3493 36.9651C14.4467 36.9651 6.36 44.6518 6.36 60.8491ZM0 60.8491C0 41.2638 10.7213 31.8425 28.3493 31.8425C45.976 31.8425 56.6987 41.2638 56.6987 60.8491C56.6987 80.2705 45.976 89.7731 28.3493 89.7731C10.7213 89.7731 0 80.2705 0 60.8491Z"
                  fill="white"
                />
                <path
                  d="M77.9642 56.056V86.6334C77.9642 88.1201 76.6935 89.2787 75.0575 89.2787C73.4228 89.2787 72.1495 88.1201 72.1495 86.6334V56.056H69.0602C67.5162 56.056 66.3335 54.9814 66.3335 53.576C66.3335 52.172 67.5162 51.0974 69.0602 51.0974H72.1495V46.2214C72.1495 38.784 76.7828 32.0067 87.8682 32.0067C89.4135 32.0067 90.5948 33.0814 90.5948 34.4867C90.5948 35.8907 89.4135 36.9654 87.8682 36.9654C80.7815 36.9654 77.9642 40.8494 77.9642 46.2214V51.0974H96.4095V46.2214C96.4095 38.784 101.044 32.0067 112.128 32.0067C116.125 32.0067 120.124 32.9987 121.76 33.8254C122.849 34.404 123.395 35.1467 123.395 36.1387C123.395 37.544 122.215 38.6187 120.669 38.6187C120.124 38.6187 119.488 38.3707 118.761 38.1227C116.763 37.4614 115.127 36.9654 112.128 36.9654C105.041 36.9654 102.224 40.8494 102.224 46.2214V51.0974H122.759C124.393 51.0974 125.667 52.2547 125.667 53.7414V86.6334C125.667 88.1201 124.393 89.2787 122.759 89.2787C121.123 89.2787 119.852 88.1201 119.852 86.6334V56.056H102.224V86.6334C102.224 88.1201 100.952 89.2787 99.3175 89.2787C97.6815 89.2787 96.4095 88.1201 96.4095 86.6334V56.056H77.9642Z"
                  fill="white"
                />
                <path
                  d="M154.536 49.9402C162.623 49.9402 167.073 53.8242 168.528 55.6429C168.983 56.2202 169.165 56.6349 169.165 57.2135C169.165 58.6175 167.983 59.6095 166.529 59.6095C165.62 59.6095 164.985 59.2789 164.348 58.6175C162.985 57.2135 160.532 54.7335 154.536 54.7335C146.176 54.7335 141.725 59.6095 141.725 69.7735C141.725 80.1042 146.176 84.8989 154.536 84.8989C159.805 84.8989 162.985 82.7495 165.076 81.3442C165.712 80.9295 166.167 80.7655 166.801 80.7655C168.256 80.7655 169.437 81.8415 169.437 83.1615C169.437 83.9069 169.165 84.4842 168.256 85.2295C166.256 86.7162 161.896 89.6895 154.536 89.6895C142.815 89.6895 135.819 83.3282 135.819 69.7735C135.819 56.2202 142.815 49.9402 154.536 49.9402Z"
                  fill="white"
                />
                <path
                  d="M198.334 54.7338C190.43 54.7338 186.069 59.3618 185.523 66.7165H209.965C209.965 60.7658 207.694 54.7338 198.334 54.7338ZM198.334 49.9405C212.873 49.9405 215.689 60.4351 215.689 66.0551C215.689 68.4525 215.689 71.3445 212.054 71.3445H185.523C185.523 79.5258 190.701 84.8151 199.243 84.8151C204.514 84.8151 207.602 82.9978 210.146 81.2605C210.691 80.9298 211.055 80.7658 211.782 80.7658C213.235 80.7658 214.418 81.8418 214.418 83.1618C214.418 84.0698 213.962 84.5671 213.418 85.0631C212.054 86.3018 207.329 89.6898 199.243 89.6898C187.158 89.6898 179.618 83.6591 179.618 69.7738C179.618 57.0471 186.522 49.9405 198.334 49.9405"
                  fill="white"
                />
                <path
                  d="M153.989 25.0665C150.01 25.0665 146.03 24.9918 142.054 25.0865C136.658 25.2118 132.628 29.2838 132.602 34.4825C132.574 39.6838 136.532 43.8798 141.952 43.9785C150.002 44.1225 158.058 44.1318 166.11 43.9771C171.628 43.8705 175.405 39.7878 175.357 34.4425C175.31 29.1131 171.428 25.2011 165.921 25.0798C161.946 24.9945 157.965 25.0638 153.989 25.0665Z"
                  fill="white"
                />
                <path
                  d="M179.439 22.6974C179.439 26.9054 179.358 31.1147 179.462 35.3201C179.586 40.5107 183.551 44.2294 188.769 44.2227C193.893 44.2161 198.003 40.3974 198.073 35.2667C198.182 27.0481 198.175 18.8267 198.075 10.6081C198.011 5.3774 193.747 1.41207 188.562 1.48274C183.531 1.5534 179.549 5.50674 179.449 10.6614C179.369 14.6721 179.431 18.6867 179.439 22.6974Z"
                  fill="white"
                />
                <path
                  d="M175.29 12.948C175.29 11.476 175.403 9.99604 175.27 8.53604C174.819 3.5547 170.794 -0.0119624 165.746 3.75723e-05C160.894 0.0107042 156.712 3.66004 156.244 8.29071C155.708 13.5694 158.791 18.032 163.768 18.9494C165.487 19.2667 167.279 19.2667 169.04 19.2774C175.379 19.3134 175.379 19.292 175.29 12.948Z"
                  fill="white"
                />
                <path
                  d="M209.195 44.1999C210.46 44.1999 211.731 44.2572 212.995 44.1906C217.691 43.9386 221.496 39.9706 221.68 35.1772C221.855 30.6892 218.252 26.3146 213.823 25.6346C209.16 24.9199 204.445 27.6906 203.341 32.0706C202.876 33.9239 202.933 35.9346 202.919 37.8746C202.865 44.2319 202.893 44.2319 209.195 44.1999Z"
                  fill="white"
                />
                <path
                  d="M203.132 19.3029C203.132 19.3029 204.657 5.52555 211.793 8.33489C211.793 8.33489 217.917 15.2789 203.132 19.3029Z"
                  fill="white"
                />
                <path
                  d="M145.571 106.716C144.692 107.543 143.68 107.956 142.538 107.956C141.843 107.956 141.199 107.826 140.602 107.563C140.006 107.3 139.488 106.947 139.047 106.503C138.608 106.059 138.264 105.543 138.015 104.956C137.766 104.37 137.642 103.756 137.642 103.116C137.642 102.476 137.766 101.867 138.015 101.29C138.264 100.712 138.608 100.204 139.047 99.7695C139.488 99.3335 140.011 98.9895 140.618 98.7362C141.222 98.4829 141.882 98.3549 142.596 98.3549C143.592 98.3549 144.428 98.5455 145.102 98.9229C145.776 99.3002 146.284 99.8002 146.627 100.423L145.367 101.248C145.083 100.735 144.692 100.348 144.194 100.09C143.696 99.8322 143.148 99.7029 142.552 99.7029C142.063 99.7029 141.618 99.7989 141.218 99.9895C140.818 100.182 140.475 100.434 140.192 100.748C139.908 101.066 139.691 101.431 139.54 101.85C139.388 102.267 139.314 102.703 139.314 103.156C139.314 103.635 139.398 104.084 139.568 104.503C139.74 104.92 139.975 105.286 140.271 105.596C140.571 105.908 140.926 106.154 141.335 106.336C141.746 106.519 142.191 106.61 142.67 106.61C143.196 106.61 143.703 106.499 144.186 106.276C144.67 106.054 145.132 105.703 145.571 105.223V104.21H143.387V103.103H146.95V107.89H145.571V106.716Z"
                  fill="white"
                />
                <path
                  d="M151.347 103.276H154.219C154.455 103.276 154.667 103.229 154.858 103.136C155.048 103.043 155.211 102.915 155.35 102.748C155.486 102.584 155.594 102.396 155.672 102.183C155.751 101.969 155.79 101.743 155.79 101.503C155.79 101.263 155.743 101.036 155.65 100.823C155.556 100.609 155.435 100.423 155.283 100.263C155.131 100.103 154.956 99.9762 154.756 99.8828C154.555 99.7895 154.347 99.7428 154.132 99.7428H151.347V103.276ZM149.706 107.889V98.4228H154.235C154.704 98.4228 155.136 98.5122 155.532 98.6895C155.928 98.8668 156.267 99.1002 156.551 99.3895C156.834 99.6788 157.056 100.008 157.218 100.376C157.379 100.745 157.46 101.121 157.46 101.503C157.46 101.823 157.411 102.129 157.314 102.423C157.215 102.716 157.079 102.987 156.903 103.236C156.727 103.484 156.511 103.7 156.259 103.883C156.003 104.065 155.724 104.2 155.423 104.289L157.871 107.889H156.008L153.751 104.596H151.347V107.889H149.706Z"
                  fill="white"
                />
                <path
                  d="M165.567 104.21L163.896 100.143L162.166 104.21H165.567ZM163.178 98.4231H164.614L168.719 107.89H166.988L165.888 105.316H161.872L160.787 107.89H159.043L163.178 98.4231Z"
                  fill="white"
                />
                <path
                  d="M172.529 103.276H175.243C175.477 103.276 175.689 103.232 175.88 103.143C176.071 103.053 176.233 102.929 176.371 102.769C176.508 102.609 176.615 102.423 176.693 102.209C176.772 101.996 176.811 101.76 176.811 101.503C176.811 101.236 176.767 100.996 176.677 100.783C176.591 100.569 176.468 100.385 176.313 100.229C176.156 100.075 175.977 99.9548 175.777 99.8695C175.576 99.7855 175.369 99.7428 175.155 99.7428H172.529V103.276ZM170.889 107.889V98.4228H175.256C175.725 98.4228 176.159 98.5122 176.555 98.6895C176.949 98.8668 177.289 99.1002 177.573 99.3895C177.856 99.6788 178.079 100.008 178.24 100.376C178.401 100.745 178.483 101.121 178.483 101.503C178.483 101.903 178.407 102.288 178.255 102.656C178.103 103.025 177.891 103.355 177.617 103.643C177.343 103.931 177.013 104.163 176.628 104.336C176.24 104.509 175.813 104.596 175.344 104.596H172.529V107.889H170.889Z"
                  fill="white"
                />
                <path
                  d="M189.447 98.4233V107.89H187.79V103.717H182.542V107.89H180.901V98.4233H182.542V102.383H187.79V98.4233H189.447Z"
                  fill="white"
                />
                <path
                  d="M192.672 98.4234H194.312V107.89H192.672V98.4234Z"
                  fill="white"
                />
                <path
                  d="M196.878 103.09C196.878 102.521 196.99 101.954 197.217 101.39C197.441 100.826 197.772 100.318 198.206 99.8699C198.641 99.4206 199.17 99.0593 199.796 98.7833C200.421 98.5073 201.13 98.3699 201.922 98.3699C202.87 98.3699 203.681 98.5619 204.354 98.9433C205.029 99.3259 205.537 99.8233 205.88 100.437L204.589 101.223C204.444 100.939 204.268 100.698 204.062 100.503C203.857 100.307 203.632 100.151 203.389 100.037C203.144 99.9206 202.889 99.8393 202.626 99.7886C202.361 99.7406 202.108 99.7166 201.862 99.7166C201.316 99.7166 200.837 99.8166 200.426 100.015C200.017 100.217 199.673 100.481 199.394 100.81C199.116 101.138 198.905 101.507 198.762 101.915C198.621 102.326 198.55 102.734 198.55 103.143C198.55 103.606 198.633 104.046 198.8 104.462C198.965 104.881 199.197 105.249 199.496 105.569C199.794 105.89 200.148 106.145 200.558 106.337C200.969 106.527 201.418 106.622 201.906 106.622C202.161 106.622 202.422 106.594 202.69 106.537C202.961 106.478 203.22 106.386 203.468 106.257C203.717 106.127 203.948 105.965 204.157 105.77C204.368 105.574 204.541 105.339 204.678 105.062L206.041 105.77C205.876 106.125 205.64 106.439 205.337 106.71C205.034 106.981 204.693 107.21 204.31 107.395C203.93 107.582 203.526 107.726 203.102 107.823C202.677 107.921 202.258 107.969 201.849 107.969C201.116 107.969 200.446 107.827 199.84 107.543C199.236 107.258 198.712 106.887 198.272 106.43C197.832 105.971 197.49 105.451 197.245 104.87C197.001 104.287 196.878 103.694 196.878 103.09Z"
                  fill="white"
                />
                <path
                  d="M214.602 100.65C214.504 100.552 214.362 100.447 214.177 100.336C213.99 100.226 213.774 100.122 213.524 100.022C213.276 99.9245 213.001 99.8445 212.704 99.7831C212.405 99.7205 212.1 99.6885 211.788 99.6885C211.093 99.6885 210.582 99.8045 210.256 100.036C209.928 100.267 209.764 100.587 209.764 100.996C209.764 101.228 209.816 101.415 209.917 101.563C210.021 101.71 210.181 101.839 210.394 101.95C210.61 102.062 210.878 102.159 211.201 102.242C211.524 102.327 211.9 102.418 212.329 102.515C212.857 102.631 213.334 102.76 213.758 102.902C214.185 103.046 214.542 103.219 214.837 103.423C215.13 103.627 215.357 103.879 215.518 104.176C215.68 104.474 215.76 104.836 215.76 105.263C215.76 105.742 215.66 106.156 215.46 106.503C215.258 106.848 214.985 107.131 214.638 107.348C214.293 107.567 213.889 107.728 213.429 107.83C212.969 107.931 212.476 107.983 211.949 107.983C211.157 107.983 210.392 107.874 209.654 107.656C208.916 107.439 208.249 107.122 207.653 106.703L208.402 105.423C208.528 105.538 208.712 105.667 208.952 105.81C209.19 105.952 209.469 106.082 209.788 106.203C210.104 106.323 210.449 106.424 210.82 106.51C211.19 106.594 211.576 106.635 211.977 106.635C212.624 106.635 213.121 106.534 213.473 106.33C213.825 106.124 214.001 105.823 214.001 105.423C214.001 105.182 213.936 104.983 213.802 104.823C213.672 104.663 213.482 104.522 213.238 104.402C212.994 104.283 212.696 104.175 212.345 104.075C211.993 103.978 211.592 103.876 211.141 103.77C210.625 103.644 210.173 103.514 209.788 103.376C209.4 103.238 209.08 103.071 208.826 102.876C208.572 102.68 208.378 102.452 208.246 102.188C208.116 101.927 208.049 101.61 208.049 101.235C208.049 100.774 208.146 100.363 208.342 100.003C208.537 99.6431 208.804 99.3418 209.141 99.0965C209.478 98.8511 209.876 98.6671 210.337 98.5431C210.796 98.4178 211.294 98.3551 211.832 98.3551C212.534 98.3551 213.186 98.4578 213.781 98.6631C214.377 98.8671 214.9 99.1218 215.35 99.4231L214.602 100.65Z"
                  fill="white"
                />
              </svg>
            </div>
          </LogoContainer>
          <CopywrightNotice>&copy; 2021. Office Grafics</CopywrightNotice>
          <SocialLinksContainer>Derechos reservados</SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};

export default FiveColumnWithBackground;
