import Home from "./page/Home";
import "tailwindcss/dist/base.css";
import "./App.css";

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
