import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "./misc/Headings.js";
import TeamIllustrationSrc from "../images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "../images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = styled.p`
  ${tw`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-900`}
  span {
    ${tw`text-primary-500`}
  }
`;
const TwoColWithButton = ({
  subheading = "Our Expertise",
  heading = (
    <>
      Nuestra <span tw="text-primary-500">Cobertura</span>
    </>
  ),
  description = "Office GRAFICS, actualmente Ofrece sus Servicios regulares en las Principales, Ciudades del Estado de Tabasco con envíos especiales a la CDMX:",
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
}) => {
  return (
    <div id="cobertura">
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image
              css={imageCss}
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
            {imageDecoratorBlob && (
              <DecoratorBlob css={imageDecoratorBlobCss} />
            )}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>{subheading}</Subheading>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <Description>
                Villahermosa <span className="text-primary-500">|</span>{" "}
                Macuspana <span className="text-primary-500">|</span> Jalpa de
                Méndez. <span className="text-primary-500">|</span> Paraíso{" "}
                <span className="text-primary-500">|</span> Teapa{" "}
                <span className="text-primary-500">|</span> Huimanguillo{" "}
                <span className="text-primary-500">|</span> Cunduacán
              </Description>
              <Description>
                Sin embargo, en Invitaciones o Solicitudes especiales, su
                Cobertura se puede ampliar a otras áreas más alejadas como
                Oaxaca, Chiapas, Campeche, Yucatán, Veracruz, etc.
              </Description>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </div>
  );
};

export default TwoColWithButton;
