import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../helpers/useAnimatedNavToggler.js";

import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const Light = ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="#quienes">¿Quiénes somos?</NavLink>
      <NavLink href="#cobertura">Cobertura</NavLink>
      <NavLink href="#servicios">Servicios</NavLink>
      <NavLink href="#productos">Productos</NavLink>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href="#contacto"
      >
        Contacto
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <svg
        width="227"
        height="108"
        viewBox="0 0 227 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M157.801 24.7574C153.664 24.7574 149.525 24.6788 145.391 24.7774C139.78 24.9094 135.589 29.1428 135.561 34.5481C135.535 39.9588 139.648 44.3214 145.284 44.4241C153.656 44.5748 162.035 44.5841 170.405 44.4228C176.145 44.3121 180.072 40.0654 180.023 34.5081C179.973 28.9654 175.936 24.8974 170.212 24.7721C166.077 24.6814 161.939 24.7548 157.801 24.7574Z"
          fill="#FBB331"
        />
        <path
          d="M184.107 22.0165C184.107 26.3552 184.023 30.6965 184.129 35.0325C184.258 40.3858 188.346 44.2192 193.726 44.2125C199.01 44.2058 203.249 40.2685 203.321 34.9778C203.434 26.5032 203.427 18.0245 203.323 9.54985C203.258 4.15652 198.861 0.0671875 193.513 0.140521C188.327 0.211187 184.219 4.28985 184.115 9.60452C184.034 13.7405 184.099 17.8792 184.107 22.0165Z"
          fill="#EE3565"
        />
        <path
          d="M179.954 13.292C179.954 11.7814 180.069 10.2614 179.934 8.7627C179.47 3.64937 175.34 -0.0133009 170.156 3.24708e-05C165.176 0.0106991 160.884 3.75603 160.402 8.51203C159.853 13.9307 163.017 18.5107 168.126 19.4534C169.892 19.7787 171.732 19.7787 173.538 19.7894C180.046 19.8267 180.046 19.804 179.954 13.292Z"
          fill="#6291CB"
        />
        <path
          d="M213.447 44.1988C214.755 44.1988 216.067 44.2575 217.371 44.1882C222.223 43.9282 226.155 39.8295 226.346 34.8775C226.527 30.2415 222.804 25.7215 218.227 25.0188C213.411 24.2815 208.54 27.1428 207.4 31.6682C206.919 33.5828 206.979 35.6602 206.963 37.6642C206.908 44.2322 206.939 44.2322 213.447 44.1988Z"
          fill="#51C1B8"
        />
        <path
          d="M207.798 20.6611C207.798 20.6611 209.506 5.23307 217.497 8.38107C217.497 8.38107 224.354 16.1557 207.798 20.6611Z"
          fill="#90C84B"
        />
        <path
          d="M8.36 60.8487C8.36 76.9633 16.4467 84.6513 30.348 84.6513C44.2493 84.6513 52.336 76.9633 52.336 60.8487C52.336 44.6513 44.2493 36.9647 30.348 36.9647C16.4467 36.9647 8.36 44.6513 8.36 60.8487ZM2 60.8487C2 41.2633 12.7213 31.842 30.348 31.842C47.9747 31.842 58.6973 41.2633 58.6973 60.8487C58.6973 80.27 47.9747 89.7727 30.348 89.7727C12.7213 89.7727 2 80.27 2 60.8487Z"
          fill="#373535"
        />
        <path
          d="M8.36 60.8487C8.36 76.9633 16.4467 84.6513 30.348 84.6513C44.2493 84.6513 52.336 76.9633 52.336 60.8487C52.336 44.6513 44.2493 36.9647 30.348 36.9647C16.4467 36.9647 8.36 44.6513 8.36 60.8487ZM2 60.8487C2 41.2633 12.7213 31.842 30.348 31.842C47.9747 31.842 58.6973 41.2633 58.6973 60.8487C58.6973 80.27 47.9747 89.7727 30.348 89.7727C12.7213 89.7727 2 80.27 2 60.8487Z"
          stroke="#373535"
          stroke-width="2.66667"
          stroke-miterlimit="10"
        />
        <path
          d="M79.9648 56.0556V86.6329C79.9648 88.1196 78.6928 89.2783 77.0568 89.2783C75.4221 89.2783 74.1501 88.1196 74.1501 86.6329V56.0556H71.0608C69.5154 56.0556 68.3341 54.9809 68.3341 53.5756C68.3341 52.1716 69.5154 51.0969 71.0608 51.0969H74.1501V46.2209C74.1501 38.7836 78.7834 32.0063 89.8688 32.0063C91.4128 32.0063 92.5941 33.0809 92.5941 34.4863C92.5941 35.8903 91.4128 36.9649 89.8688 36.9649C82.7808 36.9649 79.9648 40.8489 79.9648 46.2209V51.0969H98.4088V46.2209C98.4088 38.7836 103.043 32.0063 114.129 32.0063C118.126 32.0063 122.125 32.9983 123.759 33.8249C124.85 34.4036 125.395 35.1463 125.395 36.1383C125.395 37.5436 124.214 38.6183 122.669 38.6183C122.125 38.6183 121.489 38.3703 120.761 38.1223C118.762 37.4609 117.127 36.9649 114.129 36.9649C107.041 36.9649 104.225 40.8489 104.225 46.2209V51.0969H124.759C126.394 51.0969 127.666 52.2543 127.666 53.7409V86.6329C127.666 88.1196 126.394 89.2783 124.759 89.2783C123.123 89.2783 121.851 88.1196 121.851 86.6329V56.0556H104.225V86.6329C104.225 88.1196 102.953 89.2783 101.317 89.2783C99.6821 89.2783 98.4088 88.1196 98.4088 86.6329V56.0556H79.9648Z"
          fill="#373535"
        />
        <path
          d="M79.9648 56.0556V86.6329C79.9648 88.1196 78.6928 89.2783 77.0568 89.2783C75.4221 89.2783 74.1501 88.1196 74.1501 86.6329V56.0556H71.0608C69.5154 56.0556 68.3341 54.9809 68.3341 53.5756C68.3341 52.1716 69.5154 51.0969 71.0608 51.0969H74.1501V46.2209C74.1501 38.7836 78.7834 32.0063 89.8688 32.0063C91.4128 32.0063 92.5941 33.0809 92.5941 34.4863C92.5941 35.8903 91.4128 36.9649 89.8688 36.9649C82.7808 36.9649 79.9648 40.8489 79.9648 46.2209V51.0969H98.4088V46.2209C98.4088 38.7836 103.043 32.0063 114.129 32.0063C118.126 32.0063 122.125 32.9983 123.759 33.8249C124.85 34.4036 125.395 35.1463 125.395 36.1383C125.395 37.5436 124.214 38.6183 122.669 38.6183C122.125 38.6183 121.489 38.3703 120.761 38.1223C118.762 37.4609 117.127 36.9649 114.129 36.9649C107.041 36.9649 104.225 40.8489 104.225 46.2209V51.0969H124.759C126.394 51.0969 127.666 52.2543 127.666 53.7409V86.6329C127.666 88.1196 126.394 89.2783 124.759 89.2783C123.123 89.2783 121.851 88.1196 121.851 86.6329V56.0556H104.225V86.6329C104.225 88.1196 102.953 89.2783 101.317 89.2783C99.6821 89.2783 98.4088 88.1196 98.4088 86.6329V56.0556H79.9648Z"
          stroke="#373535"
          stroke-width="2.66667"
          stroke-miterlimit="10"
        />
        <path
          d="M159.202 49.9398C167.288 49.9398 171.74 53.8238 173.194 55.6424C173.649 56.2198 173.83 56.6344 173.83 57.2131C173.83 58.6171 172.648 59.6091 171.195 59.6091C170.286 59.6091 169.65 59.2784 169.014 58.6171C167.651 57.2131 165.199 54.7331 159.202 54.7331C150.842 54.7331 146.39 59.6091 146.39 69.7731C146.39 80.1038 150.842 84.8984 159.202 84.8984C164.471 84.8984 167.651 82.7491 169.742 81.3438C170.378 80.9291 170.832 80.7651 171.467 80.7651C172.922 80.7651 174.103 81.8411 174.103 83.1611C174.103 83.9064 173.83 84.4838 172.922 85.2291C170.923 86.7158 166.562 89.6891 159.202 89.6891C147.48 89.6891 140.484 83.3278 140.484 69.7731C140.484 56.2198 147.48 49.9398 159.202 49.9398Z"
          fill="#373535"
        />
        <path
          d="M159.202 49.9398C167.288 49.9398 171.74 53.8238 173.194 55.6424C173.649 56.2198 173.83 56.6344 173.83 57.2131C173.83 58.6171 172.648 59.6091 171.195 59.6091C170.286 59.6091 169.65 59.2784 169.014 58.6171C167.651 57.2131 165.199 54.7331 159.202 54.7331C150.842 54.7331 146.39 59.6091 146.39 69.7731C146.39 80.1038 150.842 84.8984 159.202 84.8984C164.471 84.8984 167.651 82.7491 169.742 81.3438C170.378 80.9291 170.832 80.7651 171.467 80.7651C172.922 80.7651 174.103 81.8411 174.103 83.1611C174.103 83.9064 173.83 84.4838 172.922 85.2291C170.923 86.7158 166.562 89.6891 159.202 89.6891C147.48 89.6891 140.484 83.3278 140.484 69.7731C140.484 56.2198 147.48 49.9398 159.202 49.9398Z"
          stroke="#373535"
          stroke-width="2.66667"
          stroke-miterlimit="10"
        />
        <path
          d="M203.001 54.7333C195.095 54.7333 190.734 59.3613 190.189 66.716H214.631C214.631 60.7653 212.359 54.7333 203.001 54.7333ZM203.001 49.94C217.538 49.94 220.355 60.4347 220.355 66.0547C220.355 68.452 220.355 71.344 216.721 71.344H190.189C190.189 79.5253 195.367 84.8147 203.909 84.8147C209.179 84.8147 212.269 82.9973 214.813 81.26C215.358 80.9293 215.721 80.7653 216.447 80.7653C217.902 80.7653 219.083 81.8413 219.083 83.1613C219.083 84.0693 218.629 84.5667 218.083 85.0627C216.721 86.3013 211.995 89.6893 203.909 89.6893C191.825 89.6893 184.283 83.6587 184.283 69.7733C184.283 57.0467 191.189 49.94 203.001 49.94"
          fill="#373535"
        />
        <path
          d="M203.001 54.7333C195.095 54.7333 190.734 59.3613 190.189 66.716H214.631C214.631 60.7653 212.359 54.7333 203.001 54.7333ZM203.001 49.94C217.538 49.94 220.355 60.4347 220.355 66.0547C220.355 68.452 220.355 71.344 216.721 71.344H190.189C190.189 79.5253 195.367 84.8147 203.909 84.8147C209.179 84.8147 212.269 82.9973 214.813 81.26C215.358 80.9293 215.721 80.7653 216.447 80.7653C217.902 80.7653 219.083 81.8413 219.083 83.1613C219.083 84.0693 218.629 84.5667 218.083 85.0627C216.721 86.3013 211.995 89.6893 203.909 89.6893C191.825 89.6893 184.283 83.6587 184.283 69.7733C184.283 57.0467 191.189 49.94 203.001 49.94V49.94Z"
          stroke="#373535"
          stroke-width="2.66667"
          stroke-miterlimit="10"
        />
        <path
          d="M161.571 106.716C160.691 107.542 159.68 107.956 158.536 107.956C157.843 107.956 157.198 107.825 156.602 107.562C156.006 107.3 155.488 106.946 155.047 106.502C154.608 106.058 154.264 105.542 154.015 104.956C153.766 104.369 153.64 103.756 153.64 103.116C153.64 102.476 153.766 101.866 154.015 101.289C154.264 100.712 154.608 100.204 155.047 99.7691C155.488 99.3331 156.011 98.9891 156.616 98.7358C157.222 98.4824 157.882 98.3544 158.595 98.3544C159.592 98.3544 160.428 98.5451 161.102 98.9224C161.776 99.2998 162.284 99.7998 162.627 100.422L161.366 101.248C161.082 100.734 160.691 100.348 160.194 100.089C159.695 99.8318 159.147 99.7024 158.551 99.7024C158.063 99.7024 157.618 99.7984 157.218 99.9891C156.816 100.181 156.475 100.433 156.191 100.748C155.907 101.065 155.69 101.43 155.539 101.849C155.387 102.266 155.312 102.702 155.312 103.156C155.312 103.634 155.398 104.084 155.568 104.502C155.739 104.92 155.974 105.285 156.271 105.596C156.57 105.908 156.924 106.153 157.335 106.336C157.744 106.518 158.19 106.609 158.668 106.609C159.196 106.609 159.702 106.498 160.186 106.276C160.67 106.053 161.131 105.702 161.571 105.222V104.209H159.387V103.102H162.95V107.889H161.571V106.716Z"
          fill="#373535"
        />
        <path
          d="M167.346 103.276H170.22C170.454 103.276 170.666 103.229 170.857 103.136C171.048 103.042 171.212 102.914 171.348 102.748C171.485 102.584 171.593 102.396 171.672 102.182C171.749 101.969 171.789 101.742 171.789 101.502C171.789 101.262 171.741 101.036 171.649 100.822C171.556 100.609 171.434 100.422 171.282 100.262C171.13 100.102 170.956 99.9757 170.754 99.8824C170.554 99.789 170.346 99.7424 170.132 99.7424H167.346V103.276ZM165.705 107.889V98.4224H170.234C170.704 98.4224 171.136 98.5117 171.532 98.689C171.928 98.8664 172.266 99.0997 172.55 99.389C172.834 99.6784 173.056 100.008 173.217 100.376C173.378 100.745 173.46 101.121 173.46 101.502C173.46 101.822 173.41 102.129 173.313 102.422C173.214 102.716 173.078 102.986 172.902 103.236C172.726 103.484 172.512 103.7 172.257 103.882C172.004 104.065 171.725 104.2 171.422 104.289L173.87 107.889H172.009L169.75 104.596H167.346V107.889H165.705Z"
          fill="#373535"
        />
        <path
          d="M181.566 104.209L179.896 100.143L178.165 104.209H181.566ZM179.177 98.4227H180.613L184.718 107.889H182.988L181.889 105.316H177.872L176.788 107.889H175.042L179.177 98.4227Z"
          fill="#373535"
        />
        <path
          d="M186.887 107.889V98.4223H193.822V99.7422H188.529V102.569H192.957V103.809H188.529V107.889H186.887Z"
          fill="#373535"
        />
        <path
          d="M196.254 98.4229H197.896V107.89H196.254V98.4229Z"
          fill="#373535"
        />
        <path
          d="M200.462 103.089C200.462 102.52 200.574 101.953 200.799 101.389C201.023 100.825 201.354 100.317 201.788 99.8695C202.223 99.4201 202.754 99.0588 203.379 98.7828C204.004 98.5068 204.712 98.3695 205.504 98.3695C206.452 98.3695 207.264 98.5615 207.938 98.9428C208.612 99.3255 209.12 99.8228 209.462 100.436L208.172 101.223C208.026 100.939 207.851 100.697 207.644 100.503C207.439 100.307 207.215 100.151 206.971 100.036C206.726 99.9201 206.472 99.8388 206.208 99.7881C205.944 99.7401 205.69 99.7161 205.446 99.7161C204.899 99.7161 204.42 99.8161 204.01 100.015C203.599 100.216 203.255 100.48 202.976 100.809C202.698 101.137 202.487 101.507 202.346 101.915C202.204 102.325 202.132 102.733 202.132 103.143C202.132 103.605 202.215 104.045 202.382 104.461C202.548 104.88 202.78 105.248 203.078 105.568C203.376 105.889 203.731 106.144 204.142 106.336C204.551 106.527 205.002 106.621 205.49 106.621C205.743 106.621 206.006 106.593 206.274 106.536C206.543 106.477 206.802 106.385 207.051 106.256C207.3 106.127 207.53 105.964 207.74 105.769C207.95 105.573 208.124 105.339 208.26 105.061L209.624 105.769C209.458 106.124 209.223 106.439 208.92 106.709C208.618 106.98 208.275 107.209 207.894 107.395C207.514 107.581 207.11 107.725 206.684 107.823C206.259 107.92 205.842 107.968 205.431 107.968C204.698 107.968 204.028 107.827 203.423 107.543C202.818 107.257 202.295 106.887 201.854 106.429C201.415 105.971 201.072 105.451 200.828 104.869C200.584 104.287 200.462 103.693 200.462 103.089Z"
          fill="#373535"
        />
        <path
          d="M218.184 100.649C218.087 100.552 217.944 100.447 217.759 100.336C217.574 100.225 217.356 100.121 217.107 100.021C216.858 99.924 216.584 99.844 216.286 99.7827C215.988 99.72 215.683 99.688 215.37 99.688C214.676 99.688 214.166 99.804 213.838 100.036C213.511 100.267 213.347 100.587 213.347 100.996C213.347 101.228 213.399 101.415 213.5 101.563C213.603 101.709 213.763 101.839 213.978 101.949C214.192 102.061 214.462 102.159 214.783 102.241C215.106 102.327 215.483 102.417 215.912 102.515C216.44 102.631 216.916 102.76 217.342 102.901C217.767 103.045 218.126 103.219 218.419 103.423C218.712 103.627 218.939 103.879 219.1 104.176C219.262 104.473 219.343 104.836 219.343 105.263C219.343 105.741 219.242 106.156 219.043 106.503C218.842 106.848 218.568 107.131 218.222 107.348C217.875 107.567 217.471 107.728 217.012 107.829C216.552 107.931 216.059 107.983 215.531 107.983C214.74 107.983 213.975 107.873 213.238 107.656C212.499 107.439 211.832 107.121 211.236 106.703L211.984 105.423C212.111 105.537 212.294 105.667 212.534 105.809C212.772 105.952 213.051 106.081 213.37 106.203C213.686 106.323 214.031 106.424 214.403 106.509C214.774 106.593 215.159 106.635 215.56 106.635C216.206 106.635 216.704 106.533 217.056 106.329C217.407 106.124 217.583 105.823 217.583 105.423C217.583 105.181 217.518 104.983 217.386 104.823C217.254 104.663 217.066 104.521 216.822 104.401C216.576 104.283 216.279 104.175 215.927 104.075C215.575 103.977 215.175 103.876 214.724 103.769C214.207 103.644 213.755 103.513 213.37 103.376C212.983 103.237 212.663 103.071 212.408 102.876C212.155 102.68 211.962 102.452 211.83 102.188C211.698 101.927 211.631 101.609 211.631 101.235C211.631 100.773 211.73 100.363 211.926 100.003C212.12 99.6427 212.387 99.3413 212.724 99.096C213.062 98.8507 213.459 98.6667 213.919 98.5427C214.378 98.4173 214.876 98.3547 215.414 98.3547C216.118 98.3547 216.768 98.4573 217.363 98.6627C217.96 98.8667 218.483 99.1213 218.932 99.4227L218.184 100.649Z"
          fill="#373535"
        />
      </svg>
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          <NavLinks key={1}>
            <NavLink onClick={toggleNavbar} href="#quienes">
              ¿Quiénes somos?
            </NavLink>
            <NavLink onClick={toggleNavbar} href="#cobertura">
              Cobertura
            </NavLink>
            <NavLink onClick={toggleNavbar} href="#servicios">
              Servicios
            </NavLink>
            <NavLink onClick={toggleNavbar} href="#productos">
              Productos
            </NavLink>
            <PrimaryLink
              css={roundedHeaderButton && tw`rounded-full`}
              href="#contacto"
            >
              Contacto
            </PrimaryLink>
          </NavLinks>
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

export default Light;
